import { graphql, useStaticQuery } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import React from 'react'
import BottleneckIcon from '../assets/images/home/icons/bottleneck-square.svg'
import FastIcon from '../assets/images/home/icons/fast.svg'
import LockIcon from '../assets/images/home/icons/lock.svg'
import MetaImage from '../assets/images/meta.png'
import OverviewIcon from '../assets/images/product/icons/product-overview.svg'
import HeartIcon from '../assets/images/product/overview/heart.svg'
import Layout from '../components/Layout'
import CTABlock from '../components/blocks/CTABlock'
import { gridSpacing } from '../components/blocks/ColumnsBlock'
import { DarkLogoBlock } from '../components/blocks/DarkLogoBlock'
import HomeHeroBlock from '../components/blocks/HomeHeroBlock'
import LeadBlock from '../components/blocks/LeadBlock'
import { OverviewBlock } from '../components/blocks/OverviewBlock'
import { OverviewVideoBlock } from '../components/blocks/OverviewVideoBlock'
import { PersonasBlock } from '../components/blocks/PersonasBlock'
import QuoteBlock from '../components/blocks/QuoteBlock'
import { UnblockedBlock } from '../components/blocks/UnblockedBlock'
import { FeaturedBlogArticles } from '../components/changelog/FeaturedBlogArticles'
import Block from '../components/primitives/Block'
import Box from '../components/primitives/Box'
import Row from '../components/primitives/Row'
import Stack from '../components/primitives/Stack'
import Blurb from '../components/system/Blurb'
import Br from '../components/system/Br'
import Button from '../components/system/Button'
import { responsiveScale } from '../styles/helpers'

export const query = graphql`
  {
    backgroundImage: file(
      relativePath: { eq: "images/product/overview/hero-bg.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    everyTeam: file(
      relativePath: { eq: "images/home/every-team-is-different.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 612)
      }
    }
    integrateAndScale: file(
      relativePath: { eq: "images/home/easy-to-integrate.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 612)
      }
    }
  }
`

const IndexPage = () => {
  const data = useStaticQuery(query)
  return (
    <Layout
      titleOverride="Swarmia: Rethink developer productivity"
      description="Build better software faster with insights that power your whole engineering organization. Get started with a demo or a free 14-day trial."
      metaImage={MetaImage}
      isNew
      variant="dark"
    >
      <div // This is used by Header to change the color when scrolling past the header.
        className="hero-block"
      >
        <HomeHeroBlock />
        <Box
          css={`
            margin-top: -160px;
          `}
        >
          <DarkLogoBlock />
        </Box>
        <OverviewBlock />
      </div>
      {/* This is split into it's own component so that we can change
      the nav color before the video, which is light */}
      <OverviewVideoBlock />
      <LeadBlock
        icon={OverviewIcon}
        heading={
          <>
            Powerful insights
            <Br /> at every level
          </>
        }
        content={
          <>
            Swarmia serves the whole engineering organization from developers
            <Br /> to managers and from directors to VPs.
          </>
        }
      />
      <PersonasBlock />
      <LeadBlock
        paddingTop={{ xs: 32, md: 64 }}
        icon={BottleneckIcon}
        heading={
          <>
            Works with your tools
            <Br /> and processes
          </>
        }
      />
      <Block
        paddingTop={responsiveScale(32)}
        paddingBottom={responsiveScale(92)}
      >
        <Stack
          maxWidth="largeBlock"
          space={gridSpacing}
          justifyContent="center"
          css={{ margin: 'auto' }}
        >
          <Row
            width="100%"
            space={gridSpacing}
            flexDirection={{ xs: 'column', md: 'row' }}
          >
            <Box flex={1}>
              <Blurb
                title="Every team is different"
                text={
                  <>
                    There’s no single right way to build software.
                    <Br /> We adapt to your teams’ tools, rituals, and habits.
                  </>
                }
                image={getImage(data.everyTeam)}
                isLarge
              />
            </Box>
            <Box flex={1}>
              <Blurb
                title="Easy to integrate and scale"
                text={
                  <>
                    Swarmia is built to work with your internal systems
                    <Br display={{ xs: 'none', lg: 'inline' }} /> and the tools
                    you already use.
                  </>
                }
                image={getImage(data.integrateAndScale)}
                isLarge
              />
            </Box>
          </Row>
          <Row space={gridSpacing} flexDirection={{ xs: 'column', md: 'row' }}>
            <Row
              space={gridSpacing}
              flex={1}
              flexDirection={{
                xs: 'column',
                sm: 'row',
                md: 'column',
                lg: 'row',
              }}
            >
              <Box flex={1}>
                <Blurb
                  icon={HeartIcon}
                  title="No harmful metrics"
                  text="Swarmia is not a tool for spying on developers. We only surface healthy, research-backed insights."
                />
              </Box>
              <Box flex={1}>
                <Blurb
                  icon={FastIcon}
                  title={
                    <>
                      Fast onboarding
                      <Br /> and flexible rollout
                    </>
                  }
                  text="Setting up Swarmia is easy. You can either do it yourself or get support from our customer success team."
                />
              </Box>
            </Row>
            <Box flex={1}>
              <Blurb
                icon={LockIcon}
                title="Designed security-first"
                text={
                  <>
                    <Box>
                      Swarmia is{' '}
                      <Box.span fontWeight={700}>SOC 2 Type 2</Box.span>{' '}
                      compliant and we conduct security audits twice a year to
                      keep your data safe. We’re happy to support comprehensive
                      security reviews, and work with your security team before
                      you connect to Swarmia.
                    </Box>
                    <Button
                      href="/security/"
                      variant="leadLink"
                      marginTop={12}
                      arrow
                      fontSize={18}
                    >
                      How we keep your data secure
                    </Button>
                  </>
                }
                isLarge
                isFullHeight={false}
              />
            </Box>
          </Row>
        </Stack>
      </Block>
      <QuoteBlock
        person="alex2"
        button={{ text: 'Read story', href: '/story/gorgias/' }}
      />
      <Box paddingTop={responsiveScale(64)}>
        <CTABlock
          title={
            <>
              The best teams get better all the time.
              <Br /> Start using Swarmia today.
            </>
          }
        />
      </Box>
      <FeaturedBlogArticles
        heading="Latest from the Swarmia blog"
        articles="latest"
        newStyles
      />
      <UnblockedBlock />
    </Layout>
  )
}
export default IndexPage
